import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import classes from "./articles.module.scss"
import Layout from "../components/layout"
import Image from "gatsby-image"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

import { navigate } from "gatsby"

export default function AllArticles() {
  let data = useStaticQuery(graphql`
    query {
      allDatoCmsArticle(
        filter: { locale: { eq: "en" } }
        sort: { order: ASC, fields: position }
      ) {
        edges {
          node {
            id
            title
            slug
            author
            category
            excerpt
            position
            content
          }
        }
      }
    }
  `)

  const articles = data.allDatoCmsArticle.edges

  return (
    <>
      <Layout pageTitle={"Articles"}>
        <div className={classes.root}>
          <div className={classes.titleContainer}>
            <h1 className={classes.title}>Merchant Documentation</h1>
            <div className={classes.subtitle}>
              Documentation help and how-to articles to help you get started
              with bolttech plug-and-play and make integration as fast and easy
              as possible.
            </div>
          </div>

          <section className={classes.allArticles}>
            {articles.map((node, index) => {
              const article = node.node
              const { title, category, excerpt } = article
              return (
                <div
                  key={index}
                  className={`${classes.article}`}
                  onClick={() => {
                    navigate(`/article/${article.slug}`)
                  }}
                  onKeyDown={() => {
                    navigate(`/article/${article.slug}`)
                  }}
                  role="link"
                  tabIndex={0}
                >
                  <div className={classes.article}>
                    <div className={classes.articleCategory}>{category}</div>
                    <h4 className={classes.articleTitle}>{title}</h4>
                    <p className={classes.articleExcerpt}>{excerpt}</p>

                    <Link
                      className={classes.linkblog}
                      to={`/article/${article.slug}`}
                    >
                      Read More
                      <ArrowForwardIosIcon></ArrowForwardIosIcon>
                    </Link>
                  </div>
                </div>
              )
            })}
          </section>
        </div>
      </Layout>
    </>
  )
}
